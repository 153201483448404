import { PriceSchemePriceType, getPriceTypeConstraint, getPriceScheme } from '../../selectors/experienceSelector';
import { ListItem, ListItemContent, ListItemImage } from '../List';
import { getGiftItemFeeAmount } from '../../selectors/feeSelector';
import GiftItemBreakdown from './GiftItemBreakdown';
import GiftItemButtons from './GiftItemButtons';
import GiftCodeInput from '../GiftCodeInput';
import ProductImage from '../ProductImage';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Spinner from '../Spinner';
import { isShowGiftDemographicsEnabled } from '../../selectors/sellerSelectors';
import SpinnerGroup from '../SpinnerGroup';
import _ from 'lodash';
import { getDiscountAmount } from '../../selectors/productsSelector';
import CustomGiftAmountValidationError from '../CustomGiftAmountValidationError';
import { getGiftItemAmount } from '../../selectors/giftSelector';
import { MAXIMUM_CUSTOM_AMOUNT } from '../../actions/giftActions';

class ExperienceGiftItem extends Component {
    handleDemographicChange = quantity => {
        const { onChange, giftItemIndex, priceScheme, priceTypeConstraint } = this.props;

        const baseAmount =
            priceTypeConstraint.priceType === PriceSchemePriceType.PERSON
                ? priceScheme.price * quantity
                : priceScheme.price;

        onChange(giftItemIndex, { baseAmount, quantity });
    };

    handleSpinnerGroupChange = (demographic, product, quantity) => {
        const { onChange, giftItemIndex, giftItem, priceTypeConstraint } = this.props;
        const priceScheme = getPriceScheme(product, quantity);

        const demographics = giftItem.demographics.map(giftItemDemographic => {
            if (giftItemDemographic.id === demographic.id) {
                return {
                    ...giftItemDemographic,
                    quantity,
                };
            }
            return giftItemDemographic;
        });

        const baseAmount =
            priceTypeConstraint.priceType === PriceSchemePriceType.PERSON
                ? _.sumBy(demographics, demographic => {
                      const demographicPrice = demographic.discount
                          ? priceScheme.price - getDiscountAmount(priceScheme.price, demographic.discount)
                          : priceScheme.price;
                      return demographicPrice * demographic.quantity;
                  })
                : priceScheme.price;

        quantity = _.sumBy(demographics, demographic => demographic.quantity);
        onChange(giftItemIndex, { baseAmount, quantity, demographics });
    };

    handleCodeChange = code => {
        const { onChange, giftItemIndex } = this.props;
        onChange(giftItemIndex, { code });
    };

    render() {
        const {
            experience,
            giftItemIndex,
            giftItem,
            showEditButton,
            showDeleteButton,
            isShowGiftDemographicsEnabled,
            showCode,
            showInput,
            disableCode,
            isDuplicateCode,
            giftItemAmount,
        } = this.props;

        const isInvalid = giftItemAmount > MAXIMUM_CUSTOM_AMOUNT;

        return (
            <ListItem padding={false}>
                <ListItemImage>
                    <ProductImage product={experience} />

                    <GiftItemButtons
                        giftItemIndex={giftItemIndex}
                        showEditButton={showEditButton}
                        showDeleteButton={showDeleteButton}
                    />
                </ListItemImage>

                <ListItemContent>
                    <Row>
                        <Col className="mb-2 mb-md-0" md={6}>
                            <h5 notranslate="">{experience.name}</h5>
                            <p className="text-muted" notranslate="">
                                {experience.excerpt}
                            </p>

                            {showCode ? (
                                <GiftCodeInput
                                    style={{ maxWidth: 200 }}
                                    onChange={this.handleCodeChange}
                                    value={giftItem.code}
                                    disabled={disableCode}
                                    error={isDuplicateCode ? 'Duplicate code' : null}
                                />
                            ) : null}

                            {showInput ? (
                                isShowGiftDemographicsEnabled ? (
                                    <SpinnerGroup
                                        giftItemIndex={giftItemIndex}
                                        onChange={this.handleSpinnerGroupChange}
                                        product={experience}
                                    />
                                ) : (
                                    <Spinner
                                        value={giftItem.quantity}
                                        min={1}
                                        max={experience.group.outingMax}
                                        onChange={this.handleDemographicChange}
                                    >
                                        <i className="fas fa-users mr-2" /> Guests
                                    </Spinner>
                                )
                            ) : null}

                            {isInvalid ? <CustomGiftAmountValidationError giftItemAmount={giftItemAmount} /> : null}
                        </Col>

                        <Col md={6}>
                            <GiftItemBreakdown giftItem={giftItem} />
                        </Col>
                    </Row>
                </ListItemContent>
            </ListItem>
        );
    }
}

const mapStateToProps = (state, props) => {
    const experience = props.product.experience;

    return {
        experience,
        priceScheme: getPriceScheme(experience, props.giftItem.quantity),
        priceTypeConstraint: getPriceTypeConstraint(experience),
        feeAmount: getGiftItemFeeAmount(state, props.giftItem),
        giftItemAmount: getGiftItemAmount(state, props.giftItem),
        isShowGiftDemographicsEnabled: isShowGiftDemographicsEnabled(state),
    };
};

export default connect(mapStateToProps)(ExperienceGiftItem);
