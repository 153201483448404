import { ListItem, ListItemImage, ListItemContent } from '../List';
import { MAXIMUM_CUSTOM_AMOUNT, MINIMUM_CUSTOM_AMOUNT } from '../../actions/giftActions';
import ProductItemBreakdown from './ProductItemBreakdown';
import { CurrencyInput } from '../Currency';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import ProductImage from '../ProductImage';
import CustomGiftAmountValidationError from '../CustomGiftAmountValidationError';
import { getGiftItemAmount } from '../../selectors/giftSelector';

class CustomAmountProductItem extends Component {
    state = { baseAmount: 0, giftItemAmount: 0, touched: false, seller: this.props.seller, gift: this.props.gift };

    el = createRef();

    handleAmountChange = values => {
        const baseAmount = values.floatValue || 0;
        const giftItemAmount = getGiftItemAmount(this.state, { baseAmount: baseAmount });
        this.setState({ baseAmount, giftItemAmount, touched: true });
    };

    handleSelect = () => {
        if (this.state.baseAmount >= MINIMUM_CUSTOM_AMOUNT && this.state.giftItemAmount <= MAXIMUM_CUSTOM_AMOUNT) {
            this.props.onSelect(this.props.product, this.state.baseAmount);
        } else {
            this.setState({ touched: true });
            this.el.current.focus();
        }
    };

    isInvalid = giftItemAmount => {
        const { touched, baseAmount = 0 } = this.state;
        return touched && (baseAmount < MINIMUM_CUSTOM_AMOUNT || giftItemAmount > MAXIMUM_CUSTOM_AMOUNT);
    };

    render() {
        const { product, isSelected, onClick } = this.props;
        const { baseAmount, giftItemAmount } = this.state;
        const isInvalid = this.isInvalid(giftItemAmount);

        return (
            <ListItem onClick={onClick} isSelected={isSelected} align hover>
                <ListItemImage>
                    <ProductImage product={product} />
                </ListItemImage>

                <ListItemContent>
                    <Row className="align-items-center">
                        <Col className="mb-2 mb-md-0" md={5}>
                            <h5>Choose an Amount</h5>

                            <CurrencyInput
                                aria-label={'Choose An Amount'}
                                style={{ maxWidth: 200 }}
                                onChange={this.handleAmountChange}
                                inputRef={this.el}
                                isInvalid={isInvalid}
                            />

                            {isInvalid ? (
                                <CustomGiftAmountValidationError
                                    baseAmount={baseAmount}
                                    giftItemAmount={giftItemAmount}
                                />
                            ) : null}
                        </Col>

                        <Col className="mb-2 mb-md-0" md={4}>
                            <ProductItemBreakdown
                                product={product}
                                baseAmount={baseAmount}
                                isCustomAmountBreakdown={true}
                            />
                        </Col>

                        <Col md={{ offset: 1, size: 2 }}>
                            {isSelected ? (
                                <Button color="success" onClick={this.handleSelect} block>
                                    Select
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </ListItemContent>
            </ListItem>
        );
    }
}

const mapStateToProps = state => ({
    seller: state.seller,
    gift: state.gift,
});

export default connect(mapStateToProps)(CustomAmountProductItem);
