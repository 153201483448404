import React, { Component, Fragment } from 'react';
import { MAXIMUM_CUSTOM_AMOUNT, MINIMUM_CUSTOM_AMOUNT } from '../actions/giftActions';
import Currency from './Currency';

class CustomGiftAmountValidationError extends Component {
    render() {
        const {
            baseAmount,
            giftItemAmount,
            maxAmount = MAXIMUM_CUSTOM_AMOUNT,
            minAmount = MINIMUM_CUSTOM_AMOUNT,
        } = this.props;
        const isAmountExceedsLimit = giftItemAmount > maxAmount;
        const isAmountLessThanMinLimit = baseAmount < minAmount;

        return (
            <Fragment>
                {isAmountExceedsLimit ? (
                    <small className="text-danger">
                        Maximum allowed amount including fees is <Currency>{maxAmount}</Currency>
                    </small>
                ) : null}

                {isAmountLessThanMinLimit ? (
                    <small className="text-danger">
                        Minimum amount is <Currency>{minAmount}</Currency>
                    </small>
                ) : null}
            </Fragment>
        );
    }
}

export default CustomGiftAmountValidationError;
