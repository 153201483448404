import _ from 'lodash';
import { DiscountAmountType } from './productsSelector';

export const getPriceScheme = (experience, quantity) => {
    const { priceSchemes, allowedPrivacies } = experience;
    const privacy = allowedPrivacies.length === 1 ? allowedPrivacies[0] : ConstraintPrivacy.PUBLIC;

    const priceScheme = priceSchemes.find(priceScheme => {
        const privacyConstraint = getConstraintWithObjectType(priceScheme.constraints, ConstraintObject.PRIVACY);
        const quantityConstraint = getConstraintWithObjectType(priceScheme.constraints, ConstraintObject.QUANTITY);

        let isValid = isValidPrivacyConstraint(privacyConstraint, privacy);

        if (isValid && quantityConstraint) {
            isValid = isValidQuantityConstraint(quantityConstraint, quantity);
        }
        return isValid;
    });
    if (priceScheme) {
        return priceScheme;
    }
};

export const isValidQuantityConstraint = (constraint, quantity) => {
    return quantity >= constraint.min && quantity <= constraint.max;
};

export const isValidPrivacyConstraint = (constraint, privacy) => {
    return constraint.privacy === privacy;
};

export const getConstraintWithObjectType = (constraints, objectType) => {
    return _.find(constraints, { object: objectType });
};

export const getPriceTypeConstraint = experience => {
    const priceScheme = _.find(experience.priceSchemes, {
        constraints: [{ object: ConstraintObject.PRICE_TYPE }],
    });

    return _.find(priceScheme.constraints, { object: ConstraintObject.PRICE_TYPE });
};

export const getDemographicPrice = demographic => {
    let demographicPrice = demographic.price;
    if (
        demographic.discount &&
        demographic.discount.amount &&
        demographic.discount.amountType === DiscountAmountType.ABSOLUTE
    ) {
        demographicPrice += demographic.discount.amount;
    }
    return demographicPrice;
};

export const ConstraintPrivacy = {
    PRIVATE: 'private',
    PUBLIC: 'public',
};

export const ConstraintObject = {
    PRIVACY: 'privacy_constraint',
    PRICE_TYPE: 'price_type_constraint',
    QUANTITY: 'quantity_constraint',
};

export const PriceSchemePriceType = {
    PERSON: 'person',
    OUTING: 'outing',
};
