import getUserLocale from 'get-user-locale';
import { round } from 'mathjs';
import _ from 'lodash';

const userLocale = getUserLocale();

export const format = (amount, currency, locale = userLocale) => {
    const style = currency ? 'currency' : 'decimal';
    return new Intl.NumberFormat(locale, { style, currency }).format(amount);
};

export const getSymbol = (currency, locale = userLocale) => {
    const style = currency ? 'currency' : 'decimal';

    return (0)
        .toLocaleString(locale, { style, currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
        .replace(/\d/g, '')
        .trim();
};

export const roundCurrency = (num, currency) => {
    num = Number(num);

    if (isZeroDecimal(currency)) {
        return round(num);
    } else {
        if (round(num, 3) === round(num, 4)) {
            num = round(num, 3);
        }
        return round(num, 2);
    }
};

export const isZeroDecimal = currency => {
    var zeroDecimalCurrencies = ['JPY', 'CLP', 'KRW', 'LAK', 'PYG', 'VND', 'VUV'];
    return _.includes(zeroDecimalCurrencies, currency);
};
